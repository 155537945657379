<template>
  <form
    @submit.prevent="submit"
    v-if="authUser"
    :class="isLoading ? 'is-busy' : ''"
    class="card"
  >
    <div class="card-body">
      <h5 class="card-title">Password</h5>

      <div class="my-3 form-floating">
        <input
          v-model.trim="form.oldPassword"
          id="oldpassword"
          type="password"
          class="form-control"
          placeholder="Old password"
        >
        <label for="oldpassword">Old password<sup class="text-danger">*</sup></label>
      </div>

      <div class="my-3 form-floating">
        <input
          v-model.trim="form.newPassword"
          id="newpassword"
          type="password"
          class="form-control"
          placeholder="New password"
        >
        <label for="newpassword">New password<sup class="text-danger">*</sup></label>
        <div class="form-text">Minimum 6 characters.</div>
      </div>

      <div class="my-3 form-floating">
        <input
          v-model.trim="form.newPassword2"
          id="newpassword2"
          type="password"
          class="form-control"
          placeholder="Confirm new password"
        >
        <label for="newpassword2">Confirm new password<sup class="text-danger">*</sup></label>
      </div>

      <ErrorMessage v-if="error" :error="error"></ErrorMessage>

      <div v-if="isSuccess" class="alert alert-success">Password has been updated!</div>

      <SubmitButton
        :text="'Change password'"
        :textBusy="'Please wait...'"
        :isLoading="isLoading"
        :disabled="isLoading || isSubmitDisabled"
        class="btn btn-primary"
      ></SubmitButton>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';

const initialState = () => ({
  isLoading: false,
  isSuccess: false,
  error: null,
  form: {
    oldPassword: '',
    newPassword: '',
    newPassword2: '',
  },
});

export default {
  name: 'UserPasswordForm',
  components: {
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    isSubmitDisabled() {
      const { oldPassword, newPassword, newPassword2 } = this.form;
      return oldPassword === ''
        || newPassword === ''
        || newPassword.length < 6
        || newPassword2 !== newPassword;
    },
  },
  methods: {
    async submit() {
      this.isLoading = true;
      this.isSuccess = false;
      this.error = null;
      try {
        const { oldPassword, newPassword } = this.form;
        await this.$store.dispatch('auth/updatePassword', {
          oldPassword,
          newPassword,
        });
        this.reset();
        this.isSuccess = true;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    reset() {
      Object.assign(this.$data, initialState());
    },
  },
  data() {
    return initialState();
  },
};
</script>
